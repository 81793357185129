    $(document).ready(function(){
    $(".mobile-menu").hide();

    $(".view").click((e) => {
        var hash  = e.target.hash;
        displayer(hash);
    });

    $(".view-mobile").click((e) => {
        var hash  = e.target.hash;
        displayer(hash);
       $(".mobile-menu").hide();
       // document.getElementById("mmenu").style.display=none;
    });

    $(".close-button").click((e)=> {
        //$(".mobile-menu").hide();
       // setTimeout($(".mobile-menu").hide(), 1000)
       // document.getElementById("mmenu").style.zIndex=0;
    });

    $(".buttonmenu").click((e) => {
        $(".mobile-menu").show();
    })

    let displayer = (hash) => {
        $(".rollo .title label").remove();
        $(".rollo .subtitle label").remove();
        $(".contenido .testo p").remove();

        var title = $("<label></label>").appendTo(".rollo .title");
        var subtitle = $("<label></label>").appendTo(".rollo .subtitle");
        var paragraph = $("<p></p>").appendTo(".contenido .testo");
    
        // gsap.to(".delimiter", {duration: .9, opacity: 0, y:-100} );
        retriever(hash).then((text, err)=>{
           // $(".bg-special").css("background-image", "url('"+text.bgimg+"')");
            var arrText = text.parrafo.split("-");
            arrText.forEach(element => {
                $(paragraph).append(`<span>${element}<span/><br/>`)
            });

            $(title).text(text.Titulo);
            $(subtitle).text(text.Subtitulo);
            gsap.to([".contenido .margen"], {duration:3, opacity:1});
            gsap.from([title, subtitle, paragraph], {duration:3 , opacity:0 } , (93));
        });
    }

    let retriever = (x) => {
        return new Promise((resolve, reject) => {
            fetch("./data.json")
            .then(response => response.json())
            .then(data => {
                var chorizo = data.find(element => element.hash == x);
                
                if(!chorizo || chorizo === 'undefined'){
                   
                    chorizo = data.find(element => element.Id === 1);
                    
                }
                
                resolve(chorizo);
            })
            .catch(e => {
                reject(e);
            });
        });
    }

    displayer(window.location.hash);
});